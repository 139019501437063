import styled from 'styled-components'
import tw from 'twin.macro'

export const Wrapper = styled.div`
  ${({ $width }) => ($width === 'page' ? tw`px-insetX` : tw`w-full max-w-tamax mx-auto`)};
`

export const Root = styled.div`
  ${tw`relative grid gap-2`};
  min-width: 0;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));

  ${({ $width, $orientation }) =>
    $width === 'page' && $orientation === 'portrait' && tw`w-full md:w-[68vw] max-w-[50rem] mx-auto`};

  img {
    object-fit: contain;
  }
`

export const ImageContainer = styled.div`
  position: relative;
`

export const Caption = styled.div`
  ${tw`mx-auto`};
  width: ${({ $widthPx }) => ($widthPx ? `${$widthPx}px` : 'auto')};
  max-width: min(110rem, 100vw);
  ${({ $width }) => ($width === 'page' ? tw`px-0` : tw`px-insetX`)};
`

export const BlurUp = styled.img`
  ${tw`absolute top-0 left-0 w-full h-full`};
  z-index: -1;
`
